@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: #303030;
}


.shadow-header {
    box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

.header-transition {
    transition: all 0.4s ease-in-out;
}

header a {
    transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

header a:hover {
    transition: all 0.4s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.banner {
    /* background-image: url(https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/007c4f75-200e-4aa0-a51b-b966a72d02a3/LaurenAnzevinoLLC-122.jpg); */
    background-image: url(./assets/images/banner.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.viewmore:hover .hover-card {
    /* transition: .25s ease-out; */
    animation-name: card;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.hover-card {
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    bottom: 27px;
    height: 0;
    /* left: 12px; */
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    position: absolute;
    width: 209px;
}

.word {
    margin: auto;
    color: white;
    font: 700 normal 24px 'tahoma';
    text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324;
}

.typed-cursor {
    opacity: 1;
    font-size: 25px;
}

.typed-cursor--blink {
    animation: typedjsBlink 0.7s infinite;
}

.review-section {
    background-color: rgba(236, 243, 249, 1);
    border-color: rgba(236, 243, 249, 1);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity .5s ease-in, min-height .5s ease-in, height .5s ease-in;
}


.review-box {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

.review-box::before {
    border: solid transparent;
    border-color: rgba(0, 0, 0, 0);
    content: "";
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
    z-index: 1;
    border-bottom-color: inherit;
    border-width: 0 30px 18px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.life-coach > h1 {
    position: relative;
    letter-spacing: 10px;
    font-family: sans-serif;
    display: table;
    margin: 40px auto;
    cursor: pointer;
    transition: .6s;
}

.life-coach > h1:before,
.life-coach > h1:after {
    content: attr(data-text);
    transition: .6s;
}

.life-coach > h1:before {
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    text-shadow:
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px;
    -webkit-mask: repeating-linear-gradient(45deg, transparent 0 3px, rgba(0, 0, 0, 0.5) 0 6px);
    mask: repeating-linear-gradient(45deg, transparent 0 3px, rgba(0, 0, 0, 0.5) 0 6px);
}

.life-coach > h1:hover {
    transform: translate(-10px, -10px);
}

.life-coach > h1:hover:before {
    text-shadow:
        1px 1px,
        2px 2px,
        3px 3px,
        4px 4px,
        5px 5px,
        6px 6px,
        7px 7px,
        8px 8px,
        9px 9px,
        10px 10px;
}





.health > h1 {
    position: relative;
    letter-spacing: 10px;
    font-family: sans-serif;
    display: table;
    margin: 40px auto;
    cursor: pointer;
    transition: .6s;
}

.health > h1:before,
.health > h1:after {
    content: attr(data-text);
    transition: .6s;
}

.health > h1:before {
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    text-shadow:
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px;
    -webkit-mask: repeating-linear-gradient(45deg, transparent 0 3px, rgba(0, 0, 0, 0.5) 0 6px);
    mask: repeating-linear-gradient(45deg, transparent 0 3px, rgba(0, 0, 0, 0.5) 0 6px);
}

.health > h1:hover {
    transform: translate(-10px, -10px);
}

.health > h1:hover:before {
    text-shadow:
        1px 1px,
        2px 2px,
        3px 3px,
        4px 4px,
        5px 5px,
        6px 6px,
        7px 7px,
        8px 8px,
        9px 9px,
        10px 10px;
}


@keyframes card {
    from {
        height: 0;
    }

    to {
        height: 150px;
    }
}

@keyframes typedjsBlink {
    50% {
        opacity: 0.0;
    }
}